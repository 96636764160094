$sm = 576px
$md = 768px
$lg = 992px
$xl = 1200px
$xll = 1600px

ageImg()
  &__age
    position absolute
    top 10px
    right 10px
    display block
    background-image url(/img/years.svg)
    background-position 100% 50%
    width 30px
    height 30px
    &_18
      background-position 100% 50%
    &_16
      background-position -90px 50%
    &_12
      background-position -60px 50%
    &_6
      background-position -30px 50%
    &_0
      background-position 0 50%
    &_none
      display none

html
  margin 0
  padding 0
  height 100%
  font-family 'Roboto', sans-serif
  font-size 16px
  line-height 1.2
  color #191D20
  font-weight 400

body
  margin 0
  padding 0
  height 100%

h1
h2
h3
h4
h5
h6
  margin 0
  padding 0
  font-size 1rem
  line-height 1.2

a
  color #E42828
  text-decoration underline
  &:hover
    color #ff0000

.heading
  font-family 'BN', sans-serif
  text-transform uppercase
  font-weight 700
  font-size 20px
  @media (min-width 600px)
    font-size 26px

.wrapper
  padding 0 8px
  margin 0 auto
  max-width 1200px
  @media (min-width $lg)
    padding 0 16px
  @media (min-width $xl)
    padding 0 24px

.page
  min-height 100%
  max-width 100%
  min-width 320px
  overflow hidden
  display flex
  flex-direction column
  background #E5E5E5

  &__header
    background #FFFFFF

  &__content
    margin 20px 0 30px 0
    flex-grow 1
    @media (min-width 600px)
      margin 60px 0 30px 0

  &__footer
    background #FFFFFF

.headingBlock
  margin 20px 0
  display flex
  flex-direction row
  flex-wrap wrap
  align-items center

  &__title
    width 100%
    flex-shrink 0
    margin-bottom 10px
    @media (min-width 600px)
      width auto
      margin-bottom 0
      margin-right 10px

  &__more
    background #E42828
    border-radius 4px
    font-size 14px
    line-height 14px
    height 14px
    padding 4px 10px
    color #fff
    text-decoration none
    margin-bottom 3px
    &:hover
      color #fff
      background #ff0000

.filmTile
  width 100%
  display block
  text-decoration none
  position relative

  ageImg()

  &__imgContainer
    width 100%
    padding-top 148.3333%
    height auto
    position relative
    overflow hidden
    background #1a1a1a

  &__title
    font-weight 400
    margin-top 10px
    font-size 16px
    line-height 20px
    width 100%
    overflow hidden
    text-overflow ellipsis
    max-height 60px
    color #191D20


  &__img
    width 100%
    height 100%
    position absolute
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)
    transition transform .2s 0s ease-out
    &:hover
      transform translateX(-50%) translateY(-50%) scale(1.1)

  &__info
    height 29px
    width calc(100% - 20px)
    display flex
    background #393939
    flex-wrap nowrap
    color #C0C0C0
    align-items center
    padding 0 10px

  &__rating
    color #46B1FF
    .ratingIcon
      rect
        fill #46B1FF

    &_low
      color #FF5858
      .ratingIcon
        rect
          fill #FF5858

    &_hight
      color #8CFF46
      .ratingIcon
        rect
          fill #8CFF46

  &__year
    display inline-block
    margin-right auto

.carousel
  white-space nowrap
  max-width 100%
  overflow hidden
  font-size 0
  &-cell
    vertical-align top
    white-space normal
    font-size 1rem
    display inline-block
    width 130px
    margin 0 10px

    @media (min-width 600px)
      width 183px

.tiles
  margin 20px -10px
  padding 0
  display flex
  flex-direction row
  max-width calc(100% + 20px)
  flex-wrap wrap
  list-style none

  &__item
    flex-shrink 1
    margin 0 10px 20px
    width calc(50% - 20px)
    @media (min-width: 450px)
      width calc(33.3333% - 20px)
    @media (min-width: 650px)
      width calc(25% - 20px)
    @media (min-width: 800px)
      width calc(20% - 20px)
    @media (min-width: 1100px)
      width calc(16.6666% - 20px)

.filters
  max-width calc(100% + 16px)
  overflow auto
  //white-space nowrap
  margin 0px -8px 40px
  display flex
  flex-direction row
  flex-wrap wrap
  @media (min-width 600px)
    margin -20px -8px 40px

  &__item
    display inline-block
    margin 0 8px
    flex-grow 1
    max-width 350px


  select
    font-family inherit
    font-weight inherit
    font-size 16px
    background #fff
    height 32px
    line-height 32px
    border none
    border-radius 16px
    padding 0 4px 0 12px
    border-right 10px solid #fff
    outline none
    margin-bottom 10px
    color #191d20
    width calc(100%)

.videoContainer
  max-width 100%

  &__bottom
    margin 30px 0 30px
    text-align center

.videoContainer

  &__button
    position relative
    color #fff
    text-decoration none
    background #E33838
    padding 10px 20px 10px 40px
    display inline-block
    border-radius 4px
    cursor pointer
    margin-bottom 10px
    &:hover
      color #fff
    &:after
      content: "";
      position: absolute;
      top: 50%;
      left: 1em;
      margin-top: -0.65em;
      border-left: 0.8em solid #fff;
      border-top: 0.65em solid hsla(0,0%,100%,0);
      border-bottom: 0.65em solid hsla(0,0%,100%,0);


div.video-js
  padding-top 56.6%;

.video-js
  //padding-top 56.6%;
  width: 100%;
  height: auto !important;
  overflow hidden
  position relative

  video
    position: absolute;
    top: 0;
    left: 0;
    width 100%
    height 100%

  .vjs-big-play-button
    top: 50%;
    left: 50%;
    height: 3em;
    width: 3em;
    line-height: 3em;
    border-radius: 2em;
    margin-left: -1.5em;
    margin-top: -1.5em;


.header
  &__wrapper
    display flex
    flex-direction row
    //overflow hidden
    align-items center
    height 70px

  &__logo
    margin 0 auto
    @media (min-width 800px)
      margin 0
      margin-right 24px

  &__search
    display none
    flex-grow 1
    @media (min-width 800px)
      display block

  &__nav
    margin-left 10px
    flex-shrink 0
    display none
    @media (min-width 800px)
      display block

  &__navContainer
    font-family "Roboto Condesed"
    font-weight 400

  &__navExtend
    display none
    @media (min-width 800px)
      display block

  &__searchToggle
    display block
    .searchToggle
      width 24px
      height 24px
      padding 3px 10px 3px 20px
      cursor pointer

      &__search
        padding 1px 0

      &__close
        display none

      svg
        path
          fill #000
      &:hover
        svg
          path
            fill #FF4545

  &_search

    .searchToggle__search
      display none
    .searchToggle__close
      width 24px
      height 24px
      display block

    .header__search
      display block
      flex-grow 1
      .search
        width 100%
      .search__input
        width calc(100% - 50px)

    .menuToggle
    .header__logo
        display none

  @media (min-width 800px)
    .header__logo
    .menuToggle
      display block
    &__searchToggle
    &__burger
      display none


  .menuToggle
    width 24px
    height 24px
    position relative
    margin 0 20px 0 10px
    cursor pointer
    span
      height 4px
      width 100%
      position absolute
      left 0
      top 50%
      margin-top -2px
      background #000
      opacity 1
      transition all .2s 0s ease-out
    &:before
      content ''
      height 4px
      width 24px
      background #000
      position absolute
      top 0
      left 0
      transition all .2s 0s ease-out
    &:after
      content ''
      height 4px
      width 24px
      background #000
      position absolute
      bottom 0
      left 0
      transition all .2s 0s ease-out









.headerNav
  display flex
  flex-direction row
  max-height 36px
  overflow hidden
  flex-wrap wrap
  font-size 14px

  &__item
    display inline-block
    height 36px
    line-height 36px
    cursor pointer
    margin 0 4px
    text-decoration none
    color #191D20
    &__ivi
      color #E33838
      padding 0 5px
      font-weight 600
      &:hover
        color #fff
        background-color #E33838
        border-radius 4px
    &_active
      margin 0
      padding 0 10px
      color #fff
      background #E33838
      border-radius 4px
      &:hover
        color #fff


.header__navContainer
  display block
  flex-direction row
  flex-wrap nowrap
  padding 0 8px 0
  height 0
  position absolute
  top 100%
  left 0
  right 0
  z-index 10
  background #fff
  transition padding-top .2s 0s ease-out, padding-bottom .2s 0s ease-out, box-shadow .2s 0s ease-out
  box-shadow none
  font-family "Roboto Condensed" !important

.header
  position relative
  //overflow: hidden
  @media (min-width 800px)
    overflow: visible
.header__wrapper
  position relative

.header_menu
  overflow: visible

  .menuToggle
    span
      opacity 0
    &:before
      border-bottom-color rgba(0, 0, 0, 0)
      transform rotate(45deg)
      top 10px
    //left -3px
    &:after
      transform rotate(-45deg)
      //top 0
      bottom 10px
  //left -4px
  .header__navContainer
    padding 40px 8px 30px
    height auto
    box-shadow 0 5px 5px -7px #000 inset, 0 5px 5px -7px #000
    display block

    > *
      display block

    .header__nav
      margin-left 0
      margin-bottom 20px
    .headerNav
      max-height initial

  @media (min-width 800px)
    .header__navContainer
      padding 0
      box-shadow none
      margin 0 0 0 12px
      display flex
      .header__nav
        margin 0
      .headerNav
        max-height 36px

@media (min-width 800px)
  .menuToggle
    display none
  .header__navContainer
    height auto
    padding 0
    margin-left 12px
    box-shadow none
    position initial
    flex-wrap nowrap
    flex-direction row
    display flex
    background transparent
    .header__nav
      margin-bottom 0
    .headerNav
      max-height 36px



.logo
  display inline-block
  margin-top 4px
  width 178px
  height 27px

.search
  &__input
    border-radius 4px
    background #F0F0F0 url("/img/searchIcon.svg") 8px 50%  no-repeat
    padding 9px 9px 9px 40px
    border none
    font inherit
    font-size 14px
    width calc(100% - 49px)
    outline none

.suggest
  margin 0
  padding 0


.episodeItem
  cursor pointer


.episodeItem
  width 100%

  &__imgContainer
    position relative
    width 100%
    padding-top 51.3661%
    overflow hidden
    background #1a1a1a
    margin-bottom 10px
  &__img
    position absolute
    top 50%
    left 50%
    width 100%
    height 100%
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.2s 0s ease-out;

  &__title
    font-size 14px
    line-height 18px
    max-height 54px
    overflow hidden
    text-overflow ellipsis
    color black

  &:hover &
    &__title
      color #E33838

    &__imgContainer
      &:after
        content ''
        border 4px solid rgba(#E33838, .7)
        position absolute
        left 0
        top 0
        right 0
        bottom 0
    &__img
      transform: translateX(-50%) translateY(-50%) scale(1.1)
  &_active &
    &__title
      color red
    &__imgContainer
      &:after
        content ''
        border 4px solid rgba(#E33838, 1)
        position absolute
        left 0
        top 0
        right 0
        bottom 0

  &_active:hover &
      &__imgContainer
        &:after
          border 4px solid rgba(#E33838, 1)



.flickity-button

  width 34px
  height 34px
  margin-top -15px
  //background transparent
  //&:hover
  //  background transparent

  //display none
  transition opacity 0.2s 0s ease-out

  display none
  &:focus
    box-shadow initial
  &:active
    box-shadow 0 0 0 5px red
  @media (min-width 800px)
    display block

.flickity-button[disabled="true"]
  opacity 0

.episodes
  margin 20px 0 40px

  &__header
    margin-bottom 40px
    text-align center
    font-size 18px
    letter-spacing .09em

  &__title
    text-transform uppercase
    margin-bottom 10px

  &__links
    border-bottom 1px solid #aaa
    white-space nowrap

  &__seasons
    text-decoration none
    display inline-block
    font-size 20px
    padding 10px 13px
    border-bottom 4px solid transparent
    margin-bottom -5px
    color #191d20
    &:hover
      color #E33838
      border-bottom 4px solid rgba(#E33838, .7)
    &_active
      color #E33838
      border-bottom 4px solid rgba(#E33838)
      font-weight 700
      &:hover
        border-bottom 4px solid rgba(#E33838)

.hidden
  display none

.filmDescription
  display flex
  flex-direction column
  margin-bottom 20px
  //border-bottom 1px solid #aaa
  clear both
  overflow hidden
  width 100%

  ageImg()

  &__table

    a
      color #191d20
      &:hover
        color #191d20
        text-decoration none

  &__topButton
    text-align center
    white-space nowrap
    margin 20px 0 10px
    .videoContainer__button
      padding 10px 15px 10px 35px

  &__imgBlock
    margin 0 auto 20px

  &__imgContainer
    position relative
    margin 0 auto
    background #1a1a1a
    width 240px
    height 360px
    @media (min-width 600px)
      margin 0 0 20px
      width 176px
      height 262px
    @media (min-width 900px)
      width 240px
      height 360px

  &__img
    width 240px
    height 360px
    @media (min-width 600px)
      width 176px
      height 262px

    @media (min-width 900px)
      width 240px
      height 360px

  &__main
    flex-grow 1

  &__title
    font-size 20px
    line-height 24px
    margin-bottom 20px

  &__table
    font-size 14px
    line-height 22px
    margin-bottom 10px

  &__share
    margin 20px 0
    color #585858
    font-size 14px

  &__desc
    font-size 14px
    line-height 22px
    margin-bottom 30px

  &__desc
  &__share
    min-width 100%
    width 100%
    flex-shrink 0

  @media (min-width 600px)
    flex-direction row

    &__imgBlock
      margin-right 40px

.filmTable
  display flex
  flex-direction row
  flex-wrap wrap

  &__item
    width 100%
    display flex
    flex-direction row
    margin-bottom 10px
  &__title
    flex-shrink 0
    color #585858
    padding-right 10px
    width 90px
  &__content
    min-width 0
    flex-grow 1
    &_release
      div
        margin-bottom 10px
        &:last-child
          margin-bottom 0

  @media (min-width 900px)
    &__item
      padding-right 10px
      width calc(50% - 10px)

.ya-share2
  height 24px
  display inline-block
  vertical-align middle

.shareTitle
  display inline-block
  vertical-align middle
  margin-right 10px


.trailerItem
  display block
  color #191d20
  text-decoration none
  width 100%

  &:hover
    color #191d20

  &__container
    width 100%

  &__imgContainer
    position relative
    width 100%
    padding-top 51.3661%
    overflow hidden
    background #1a1a1a
    margin-bottom 10px
  &__img
    position absolute
    top 50%
    left 50%
    width 100%
    height 100%
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.2s 0s ease-out;

  &__title
    font-size 14px
    line-height 18px
    max-height 54px
    overflow hidden

.footer
  &__container
    padding 40px 0 60px
    display flex
    flex-direction column
  &__shareButtons
    margin 10px 0

  &__support
    text-align right
    margin-bottom -5px
    > a
      display inline-block
      margin-bottom 5px


  @media (min-width 500px)
    &__shareButtons
      margin 0 0 0 20px
      display inline-block
      vertical-align middle
    &__share
      margin 10px 0

  @media (min-width 800px)
    &__container
      flex-direction row
      flex-wrap wrap
      align-items  center

    &__support
      width 100%
      flex-shrink 0

  @media (min-width 800px)
    &__support
      width auto
      margin-left auto

.logo_footer
  width 130px
  height 26px
  margin 0 0 15px
  @media (min-width 800px)
    margin 0 20px 0 0

  .logo__img
    width 130px
    height 26px

.filmRatings
  display flex
  flex-direction row
  align-items center

  > *
    height 24px
    line-height 24px
    margin-right 20px
    &:last-child
      margin-right 0

  svg
    display inline-block
    vertical-align middle
    margin-right 5px

  &__rating
    svg
      margin-top -3px

  &__IMDbRating
    svg
      margin-top -7px

  &__rating
    color #0094FF
    svg
      path
        stroke #0094FF

    &_low
      color #ff5858
      svg
        path
          stroke #ff5858

    &_hight
      color #3c9600
      svg
        path
          stroke #3c9600

.search
  &__form
    position relative

  .suggest
    position absolute
    top calc(100% + 5px)
    left 0
    right 0
    overflow hidden
    background #fff
    box-shadow 0 0 6px -3px #000
    min-width 320px
    z-index 10000

    //&__item


  .suggestItem
    padding 4px 8px 4px 4px
    display flex
    height 36px
    flex-direction row
    flex-wrap nowrap
    align-items center
    text-decoration none
    color #191d20

    &__img
      height 36px
      width 36px
      object-fit cover
      margin-right 10px

    &__title
      margin-right auto
      font-size 14px
      padding-right 10px
      overflow hidden
      white-space nowrap
      text-overflow ellipsis

    &__year
      font-size 12px
      color #585858

    &_active
      background rgba(#000, .08)


.gallery
  &__heading
    .headingBlock
      margin-top 0

  &__main
    margin-bottom 60px

.textLogo
  display inline-block
  white-space nowrap
  text-transform uppercase
  text-decoration none
  font-family 'BN', sans-serif
  font-weight 700
  color #1a1a1a
  font-size 36px
  line-height 36px
  margin-top 3px
  &__main
    color red
  &:hover
    color #1a1a1a
  &:hover &
    &__main
      color red

// Auth form styles
.authForm
  display: flex
  flex-direction: column
.authTitle
  font-size: 42px
.authLabel
  margin-top: 20px
  margin-bottom: 10px
  font-size: 16px
  line-height: 22px
.authInput
  font-size: 14px
  padding: 9px
  width: 256px
  border: solid 1px #c5c5c5
  border-radius: 4px
  outline: none
.authButtonWrapper, .uploadButtonWrapper
  margin-top: 20px
  margin-bottom: 20px
  display: flex
  align-items: center
  gap: 20px
  flex-wrap: wrap
.authButton, .uploadButton
  font-family 'Roboto', sans-serif
  padding: 8.5px 24px
  color: #fff
  background: #e33838
  border-radius: 4px
  font-size: 16px
  font-weight: 500
  border: none
  cursor pointer
.authLink
  color: #e33838
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none

// Profile popup menu
.profileWrapper
  position: relative
  display: none !important
  @media (min-width 800px)
    display: block !important
  .visible
    display: block !important
.profileWrapper__mobile
  margin-top: 20px
  display: block
  @media (max-width 800px)
    display none
  @media (min-width 800px)
    display: none
.profileButton
  cursor: pointer
.profilePopup
  display: none
  padding: 14px 10px
  position: absolute
  top: 55px
  right: 0
  background: #fff
  box-shadow: 0px 4px 20px 0px #00000040;
.profileLinkWrapper
  padding: 6px 10px
  &:hover
    background: #ECECEC
.profileLink
  font-size: 16px
  line-height: 19px
  color: #191D20
  font-weight: 500
  text-decoration: none
  text-wrap: nowrap
  &:hover
    background: #ECECEC
    color: #191D20

// Uploader input
.uploadInput
  display: none
